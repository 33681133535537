import React from 'react';
import Navbar from './Components/Layouts/Navbar';
import Users from "./Components/Users/Users";
import GithubState from "./context/github/GithubState";
import AlertState from "./context/alert/AlertState";
import {BrowserRouter as Router,Switch,Route} from 'react-router-dom';
import Alert from "./Components/Layouts/Alert";
import About from "./Components/Pages/About";
import User from "./Components/Users/User";
import NotFound from "./Components/Pages/notfound/NotFound";

const App = () => {

    return (
        <GithubState>
            <AlertState>
                <Router>
                    <div className="App">
                        <Navbar/>

                        <div className='container mt-3'>
                            <Alert alert={alert}/>

                            <Switch>
                                <Route
                                    exact path='/'
                                    render={props => <Users/>}
                                />

                                <Route exact path='/about'
                                       component={About}
                                />

                                <Route exact path='/user/:login' component={User}/>

                                <Route component={NotFound}/>
                            </Switch>
                        </div>
                    </div>
                </Router>
            </AlertState>
        </GithubState>
    )
}

export default App;
