import React, {useContext} from 'react';
import UserItem from "./UserItem";
import Spinner from "../Layouts/Spinner";
import GithubContext from '../../context/github/githubContext';

const User = () => {

    const {loading,users} = useContext(GithubContext);

    if (loading) {
        return <Spinner/>
    }else {
        if (users.length === 0){
            return (
                <div className='jumbotron bg-danger text-white text-center mt-5 shadow'>
                        <h1 className="display-4 font-weight-normal">Welcome to Github Finder</h1>
                        <p className="lead font-weight-bold">Let me introduce you to the Github Finder Website. Here we provide you the facility to search the GitHub Users. You can search a particular user, see the profile and find out its latest repositories. You can also see the number of followers, following, public repos, and public gists of the user. </p>
                        <hr className="my-4 mx-5 border-white"/>
                        <p className='font-weight-bold'>A website by <span className='lead font-weight-bold'><u>Aayush Soni</u></span> owner of <span className='lead font-weight-bold'><u>ADAYUSHSONI</u></span> presents you the GitHub Finder website where you can find the Github Users.</p>
                        <p className="lead font-weight-bold" style={{fontSize:'30px'}}><u>Let Search</u></p>
                </div>
            )
        }
        else{
            return (
                <div className='row justify-content-center'>
                    {users.map(user => (
                        <div key={user.id} className='col-sm-12 col-md-3 col-lg-3 mt-3'>
                            <UserItem user={user}/>
                        </div>
                    ))}
                </div>
            );
        }
    }
};

export default User;
