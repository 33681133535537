import React from 'react'
import './notfound.css'
import NotFoundImage from './NotFound.png';

const NotFound = () => {
    return (
        <div className='notfound'>
            <div className='text-center'>
                <img src={NotFoundImage} alt="Not Found"/>
                <h1>This Page is Not on the Map</h1>
                <p>You told your friends you weren’t bringing your phone, to try and experience what travel was like back in the day. You bought a map and a bottle of water and carried your camera for the money shot. But the map was from 2005 and the landscape had changed. So here you are, in the middle of a large field, that the map continues to claim is a local grocer.'s</p>
            </div>
        </div>
    )
}

export default NotFound;