import React, {useContext, useEffect} from 'react';
import Spinner from '../Layouts/Spinner';
import {Link} from "react-router-dom";
import Repos from "../repos/Repos";
import GithubContext from '../../context/github/githubContext'

const User = ({match}) => {

    const {getUser,user, loading, getUserRepos} = useContext(GithubContext);

    useEffect(() => {
        getUser(match.params.login);
        getUserRepos(match.params.login);
        //eslint-disable-next-line
    },[])

    const {name,avatar_url,location,email,bio,blog,login,html_url,followers,following,public_repos,public_gists,hireable,company} = user;

    if (loading) return <Spinner/>;

    return (
        <div className='container'>
            <div className='row justify-content-center align-items-center'>
                <div className='col-sm-6'>
                    <Link to='/' className='btn btn-block btn-light rounded'><i className="fas fa-arrow-circle-left"/> Back to Search</Link>
                </div>
                <div className='col-sm-6 text-center'>
                    <div className='bg-danger py-2 btn-block text-white rounded'>
                        Hireable : {' '}
                        {hireable ? <i className="fas fa-check"/>: <i className="fas fa-times-circle"/>}
                    </div>
                </div>
            </div>
            <div className='mt-5 user'>
                <div className="card border-0" >
                    <div className="row no-gutters">
                        <div className="col-md-4 text-center">
                            <img src={avatar_url} className='img-thumbnail img-fluid' alt={avatar_url}/>
                            <h1 className="card-title">{name}</h1>
                            <h6 className="card-text"><strong>Location : <u>{location}</u></strong></h6>
                        </div>
                        <div className="col-md-8">
                            <div className="card-body py-0">
                                {bio && <p className="card-text lead font-weight-normal"><strong>Bio :</strong> {bio}</p>}
                                <a href={html_url} className="btn btn-outline-danger px-5">Visit Github Profile</a>
                                <ul className="list-group">
                                    {email && <li className="list-group-item border-0 px-0 pb-1">
                                        <p className='card-text'>Email : <strong>{email}</strong></p>
                                    </li>}
                                    {login && <li className="list-group-item border-0 px-0 pb-1">
                                        <p className='card-text'>Username : <strong>{login}</strong></p>
                                    </li>}
                                    {company && <li className="list-group-item border-0 px-0 py-1">
                                        <p className='card-text'>Company : <strong>{company}</strong></p>
                                    </li>}
                                    {blog && <li className="list-group-item border-0 px-0 py-1">
                                        <p className='card-text'>Blog : <strong>{blog}</strong></p>
                                    </li>}
                                </ul>
                                <ul className='list-group list-group-horizontal-md text-center justify-content-center'>
                                    <li className="list-group-item border-0">
                                        <p className='card-text badge badge-pill badge-danger px-3 py-2'>Followers : <strong>{followers}</strong></p>
                                    </li>
                                    <li className="list-group-item border-0">
                                        <p className='card-text badge badge-pill badge-danger px-3 py-2'>Following : <strong>{following}</strong></p>
                                    </li>
                                    <li className="list-group-item border-0">
                                        <p className='card-text badge badge-pill badge-danger px-3 py-2'>Public Repo : <strong>{public_repos}</strong></p>
                                    </li>
                                    <li className="list-group-item border-0">
                                        <p className='card-text badge badge-pill badge-danger px-3 py-2'>Public GISTS : <strong>{public_gists}</strong></p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <Repos/>
                </div>
            </div>
        </div>
    );
}

export default User;
