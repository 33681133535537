import React, {useContext} from 'react';
import AlertContext from '../../context/alert/alertContext'

const Alert = () => {
    const {alert} = useContext(AlertContext);
    return (
        alert !== null && (
            <div className={`alert alert-${alert.type} text-center`} role={alert}>
                <p className="lead font-weight-bold mb-0"><i className='fas fa-info-circle'/> {alert.msg}</p>
            </div>
        )
    );
};

export default Alert;
