import React from 'react';

const About = () => {
    return (
        <div className='text-center container'>
            <div className='card bg-danger shadow-lg mt-5 rounded-lg text-white'>
                <div className='card-body' style={{cursor:'pointer'}}>
                    <h1 className='display-2 font-weight-bold mt-5 mb-3'>ADAAYUSHSONI</h1>
                    <p className='lead font-weight-bold' style={{fontSize:'26px'}}>A Website to learn Coding, Programming, Designing, Digital Marketing, Digital Drawing and so much more.</p>
                    <p className='lead font-weight-bold' style={{fontSize:'20px'}}>By</p>
                    <h1 className='display-4 font-weight-bold mb-5'>AAYUSH SONI</h1>
                    <p className='lead font-weight-bold' style={{fontSize:'30px'}}><u>App to Search Github Users</u></p>
                    <p className='lead font-weight-bold' style={{fontSize:'40px'}}><u>VERSION 1.0.0</u></p>
                </div>
            </div>
        </div>
    );
};

export default About;
