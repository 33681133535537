import React from 'react'
import PropTypes from 'prop-types';
import Search from "./Search";
import {Link} from 'react-router-dom';

const Navbar = (props) => {
    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container">
                <Link className="navbar-brand font-weight-bold text-danger" to='/'>
                    <i className={props.icon}/>
                    {props.title}
                </Link>
                <div>
                    <ul className="navbar-nav mr-auto text-danger">
                        <li className="nav-item">
                            <Link className="nav-link font-weight-bold" to="/">Home</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link font-weight-bold" to="/about">About</Link>
                        </li>
                    </ul>
                </div>
                <div>
                    <Search/>
                </div>
            </div>
        </nav>
    )
}

Navbar.defaultProps = {
    title: 'GITHUB FINDER',
    icon:'fab fa-github mr-2'
}

Navbar.propTypes = {
    title: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
}

export default Navbar
