import React, { useReducer} from 'react';
import GithubContext from './githubContext';
import GithubReducer from './githubReducer';
import axios from 'axios';

import {
    SEARCH_USERS,
    SET_LOADING,
    CLEAR_USERS,
    GET_USER,
    GET_REPOS,
} from '../types'

let githubClientId,githubClientSecret;

if(process.env.NODE_ENV !== 'production') {
    githubClientId = process.env.REACT_APP_GITHUB_CLIENT_ID;
    githubClientSecret = process.env.REACT_APP_GITHUB_CLIENT_SECRET;
} else {
    githubClientId = process.env.GITHUB_CLIENT_ID;
    githubClientSecret = process.env.GITHUB_CLIENT_SECRET;
}

const GithubState = props => {
    const initialState = {
        users: [],
        user:{},
        repos: [],
        loading: false
    }

    const [state, dispatch] = useReducer(GithubReducer,initialState)

    /*SET LOADING*/
    const setLoading = () => dispatch({type:SET_LOADING})

    /*SEARCH USERS*/
    const searchUser = async text =>{
        /*SET LOADING STATE : TRUE*/
        setLoading();
        /*FETCH DATA FROM API*/
        const response = await axios.get(`https://api.github.com/search/users?q=${text}&client_id=${githubClientId}&client_secret=${githubClientSecret}`);
        /*SET STATE*/
        dispatch({
            type:SEARCH_USERS,
            payload:response.data.items
            })
    }

    /*GET SINGLE USER*/
    const getUser = async username =>{
        /*SET LOADING STATE : TRUE*/
        setLoading()
        /*FETCH DATA FROM API*/
        const response = await axios.get(`https://api.github.com/users/${username}?client_id=${githubClientId}&client_secret=${githubClientSecret}`);
        /*SET STATE*/
        dispatch({
            type:GET_USER,
            payload:response.data
        })

    }

    /*GET USERS REPO*/
    const getUserRepos = async username =>{
        /*SET LOADING STATE : TRUE*/
        setLoading()
        /*FETCH DATA FROM API*/
        const response = await axios.get(`https://api.github.com/users/${username}/repos?per_page=9&sort=created:asc&client_id=${githubClientId}&client_secret=${githubClientSecret}`);
        /*SET STATE*/
        dispatch({
            type: GET_REPOS,
            payload:response.data
        })
    }

    /*CLEAR SEARCH*/
    const clearUsers = () => dispatch({type:CLEAR_USERS})

    return(
        <GithubContext.Provider value={{users:state.users,user:state.user,repos: state.repos,loading: state.loading,searchUser,clearUsers,getUser,getUserRepos}}>
            {props.children}
        </GithubContext.Provider>
    )
}

export default GithubState;