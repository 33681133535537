import React, {useContext, useState} from 'react';
import GithubContext from '../../context/github/githubContext'
import AlertContext from '../../context/alert/alertContext'

const Search = () => {
    const githubContext = useContext(GithubContext);
    const alertContext = useContext(AlertContext);
    const [search,setSearch] = useState('')

    const onSubmit = (e) => {
        e.preventDefault()
        if(search === ''){
            alertContext.setAlert('Please enter Something','danger')
        }else{
            githubContext.searchUser(search)
            setSearch('')
        }
    }

    return (
        <div style={{display: '-webkit-inline-box'}}>
            <form className="form-inline" onSubmit={onSubmit}>
                <input
                    className="form-control mr-sm-2"
                    name="search"
                    type="search"
                    placeholder="Search..."
                    aria-label="Search"
                    value={search}
                    onChange={(e)=> setSearch(e.target.value)}/>

                <button
                    className="btn btn-outline-dark my-2 my-sm-0"
                    type="submit">
                    Search
                </button>
            </form>
            {githubContext.users.length > 0 &&
                <button
                    className="btn btn-danger ml-2 my-2 my-sm-0"
                    onClick={githubContext.clearUsers}>
                    Clear
                </button>
            }
        </div>
    );
}

export default Search;