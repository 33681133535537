import React from 'react';
import {Link} from 'react-router-dom';

const UserItem = ({user:{login,avatar_url,name}}) => {
    return (
        <div className='card text-center' style={{width:'16rem'}}>
            <img src={avatar_url} className="card-img-top img-fluid rounded-circle px-5 pt-2"  alt={avatar_url}/>
            <div className="card-body">
                <h5 className="card-title">{login.toUpperCase()}</h5>
                {/*<p className="pb-0">No. of Repo : <span className='font-weight-bold'>"{repos_url.length}"</span></p>*/}
                <Link to={`/user/${login}`} className="btn btn-dark">View Profile</Link>
            </div>
        </div>
    );
};

export default UserItem;
