import React from 'react'

const RepoItem = ({repo}) => {
    return (
        <div className='col-sm-12 col-md-4'>
            <div className="card mt-3 border-danger" style={{height: '90px'}}>
                <div className="card-body pb-0 text-center">
                    <div className="card-title">
                        <h5><a href={repo.html_url} className='link-text text-danger' target='_blank' rel="noopener noreferrer">{repo.name}</a></h5>
                        {repo.homepage?<h6><a href={repo.homepage} target='_blank' className='link-text text-primary' rel="noopener noreferrer">HOMEPAGE <i className="fas fa-arrow-circle-right"/> </a></h6>:<h6 className='text-danger'>NOT EXIST 😇</h6>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RepoItem;